import * as THREE from "three";
import CrossViewerInteractionManager from "./CrossViewerInteractionManager";
import { DefaultLightPreset2d, DefaultLightPreset } from "../application/LightPresets";

// HostViewer is a regular viewer, that lets you share resources (Models, Geometries & Materials) with other viewers.
// The trick is using a single HostViewer to maintain all the resources, and use its renderer to render them.
// After rendering a frame, the result gets copied into the LeechViewer's canvas.

export function createHostViewer(container, config, ViewerClass) {
    function HostViewer(container, config = {}) {
        ViewerClass.call(this, container, config);
    
        // Whenever a different viewer is being renderer, lastRenderedViewer gets updated.
        // We use this to identify when we need to recover viewer state, such as cutplanes.
        this.lastRenderedViewer = this;
    
        // A list if all viewers that are registered to this host viewer instance.
        this.viewers = [];
    
        // Map of all the models that are loaded on the host viewer.
        // Each item contains the model instance, and a counter of how many viewer's are using it.
        this.loadedModels = {};
    
        // Manages the interactions between all the registered viewers.
        this.interactionManager = new CrossViewerInteractionManager(this);
        this.originalLoadDocumentNode = this.loadDocumentNode.bind(this);
    }
    
    HostViewer.prototype = Object.create(ViewerClass.prototype);
    HostViewer.prototype.constructor = HostViewer;
    
    HostViewer.prototype.initialize = function() {
        const viewerErrorCode = ViewerClass.prototype.initialize.call(this);
    
        // Register host viewer itself, since it's also an active viewer that display models.
        this.registerViewer(this);
    
        this.originalInvalidate = this.impl.invalidate.bind(this.impl);
    
        return viewerErrorCode;
    }
    
    HostViewer.prototype.registerViewer = function(viewer) {
        this.viewers.push(viewer);
        this.interactionManager.registerViewers();
    
        viewer.impl.onBeforeRender = (workThisTick) => {
            if (workThisTick) {
                _restoreViewerState(viewer, this);
            }
        };
    
        // Needed in order to restore cutplanes before selecting an object
        const originalInvokeStack = viewer.toolController.__invokeStack.bind(viewer.toolController);
        viewer.toolController.__invokeStack = (...args) => {
            _restoreViewerState(viewer, this);
            return originalInvokeStack(...args);
        }
    
        // Whenever unloadModel is called for a viewer, we want to make sure that it's resources are not being
        // used by other viewers. In that case, use keepResources flag to prevent disposing them.
        const originalUnloadModel = viewer.impl.unloadModel.bind(viewer.impl);
        viewer.impl.unloadModel = (model) => {
            const modelKey = model.getDocumentNode().getModelKey();
    
            this.loadedModels[modelKey].usedByViewersCounter--;
            const keepResources = this.loadedModels[modelKey].usedByViewersCounter > 0;
    
            if (!keepResources) {            
                delete this.loadedModels[modelKey];
            }
    
            return originalUnloadModel(model, keepResources);
        };
    
        // When calling loadDocumentNode, we actually want to load the model into hostViewer.
        // We also have to make sure that the model is not already loaded in hostViewer, by itself or by other leech viewers.
        // This flow is:
        // - If the model is already loaded on the host viewer:
        //      - Load it to the relevant viewer.
        // - If the model is not loaded yet:
        //      - Load it on the host viewer, as hidden (loadAsHidden), and make sure to keep all other models (keepCurrentModels).
        viewer.loadDocumentNode = (avDocument, manifestNode, options = {}) => {
            const hostViewer = this;
    
            return new Promise((resolve, reject) => {
                const key = manifestNode.getModelKey();
    
                // Model already loaded
                if (hostViewer.loadedModels[key]) {
                    const res = _onModelLoaded(viewer, hostViewer, hostViewer.loadedModels[key].model, options);
                    resolve(res);
                } else {
                    // Shallow clone options object
                    const optionsClone = Object.assign({}, options);
    
                    const isLeechViewer = viewer !== hostViewer;
    
                    if (isLeechViewer) {                    
                        cleanViewerBeforeLoadModel(viewer, options);
                        optionsClone.keepCurrentModels = true;
                        optionsClone.loadAsHidden = true;
                        optionsClone.skipPrefs = true;
                    }
    
                    // If model is not loaded in host viewer, load it first on host viewer, and clone it afterwards.
                    hostViewer.originalLoadDocumentNode(avDocument, manifestNode, optionsClone).then((model) => {
                        // Leech Viewer
                        if (isLeechViewer) {
                            const res = _onModelLoaded(viewer, hostViewer, model, options);
                            resolve(res);
                        } else {
                            hostViewer.loadedModels[key] = { model, usedByViewersCounter: 1 };
    
                            hostViewer.setViewerProfile(viewer, model, options);
                            hostViewer.setViewerLight(viewer, model, options);
    
                            resolve(model);
                        }
                    }).catch(reject);
                }
            });
        };
    };
    
    HostViewer.prototype.addCrossViewerInteraction = function(interactionObject) {
        this.interactionManager.addCrossViewerInteraction(interactionObject);
    };
    
    HostViewer.prototype.setViewerProfile = function(viewer, model, options) {
        if (options.loadAsHidden) {
            return;
        }
    
        options.isAEC = model.isAEC();
        const profile = viewer.chooseProfile(options);

        // There is a problem using sao with MRT enabled. Something to do with the IdTargets.
        // Currently disabling it by default solves it, but a better fix should be done later on.
        profile.settings.ambientShadows = false;
        viewer.setProfile(profile);
    }

    HostViewer.prototype.setViewerLight = function(viewer, model, options) {
        if (options.loadAsHidden) {
            return;
        }

        // A fix for a case where one viewer has 3D model and on a different viewer a 2D model is loaded.
        // In that case, the materials gets updated with a 2D preset, that makes it appear black.
        if (viewer.impl.is2d) {
    
            viewer.impl.setLightPreset(DefaultLightPreset2d);
    
            const clearColorTopBackup = viewer.impl.clearColorTop.clone().multiplyScalar(255);
            const clearColorBottomBackup = viewer.impl.clearColorBottom.clone().multiplyScalar(255);
    
            if (options.isAEC) {
                viewer.impl.setLightPresetForAec();
            } else {
                viewer.impl.setLightPreset(DefaultLightPreset);
            }
            
            viewer.impl.toggleEnvMapBackground(false);
    
            viewer.setBackgroundColor(
                clearColorTopBackup.x, clearColorTopBackup.y, clearColorTopBackup.z,
                clearColorBottomBackup.x, clearColorBottomBackup.y, clearColorBottomBackup.z
            );
        } else {
            viewer.impl.toggleEnvMapBackground(viewer.profile.settings.envMapBackground);
        }
    }
    
    function cleanViewerBeforeLoadModel (viewer, options) {
        if (!options.keepCurrentModels && viewer.impl.hasModels()) {
            let _conf = viewer.config;
            viewer.tearDown();
            viewer.setUp(_conf);
        }
    
        // Add spinner for first model
        if (!viewer.impl.hasModels() && viewer._loadingSpinner) {
            viewer._loadingSpinner.show();
        }
    }
    
    const infiniteCutplane = new THREE.Vector4(0, 0, -1, -1e20);
    
    // We can't use matman.setCutPlanes() every frame, because it sets needsUpdate for all the materials, which is super heavy.
    // In order to skip that, we make sure that there is always at least one cutplane available, so materials include NUM_CUTPLANES > 0.
    function updateCutPlanes(viewer) {
            const cutplanes = viewer.impl.getAllCutPlanes() || [infiniteCutplane];
            const materialManager = viewer.impl.matman();
            
            const maxLength = Math.max(cutplanes.length, materialManager._cutplanes.length)

            // Empty array
            materialManager._cutplanes.length = 0;
    
            let i = 0;

            for (; i < cutplanes.length; i++) {
                materialManager._cutplanes.push(cutplanes[i].clone());
            }

            // Fill cutplanes array according to the largest cutplanes array.
            // This is needed because eventually, inside cutplanes.glsl you don't want to traverse over NUM_CUTPLANES with empty entries.
            for (;i < maxLength; i++) {
                materialManager._cutplanes.push(infiniteCutplane);
            }
    
            materialManager.forEach(mat => {
                mat.cutplanes = materialManager._cutplanes;
            }, false, true);
    }
    
    function _restoreViewerState(viewer, hostViewer) {
        if (hostViewer.lastRenderedViewer !== viewer) {
            // Update cutplanes according to the current viewer.
            updateCutPlanes(viewer);
    
            // Needed for updated resolution for 2D shaders.
            if (viewer.impl.is2d) {
                viewer.impl.updateCameraMatrices();
            }
    
            // Finally, update last viewer that got rendered.
            hostViewer.lastRenderedViewer = viewer;
        }
    };
    
    function _onModelLoaded(viewer, hostViewer, model, options) {
        cleanViewerBeforeLoadModel(viewer, options);
        const modelClone = _cloneModelToViewer(model, viewer, hostViewer);
        _setupViewer(viewer, hostViewer, modelClone, options);
    
        return modelClone;
    }
    
    function _setupViewer(viewer, hostViewer, model, options) {
        const key = model.getDocumentNode().getModelKey();
    
        if (!hostViewer.loadedModels[key]) {
            hostViewer.loadedModels[key] = { model, usedByViewersCounter: 1 };
        } else {
            hostViewer.loadedModels[key].usedByViewersCounter++;
        }
    
        viewer.impl.modelQueue().addHiddenModel(model);
    
        if (!options.loadAsHidden) {
            hostViewer.setViewerProfile(viewer, model, options);
            viewer.showModel(model);
            hostViewer.setViewerLight(viewer, model, options);
    
            if (!options.headlessViewer && viewer.createUI) {
                viewer.createUI(model);
            }
        }
    
        if (viewer._loadingSpinner) {
            viewer._loadingSpinner.hide();
        }

    };
    
     function _cloneModelToViewer(model, viewer, hostViewer) {
        // model.clone() returns a shallow copy of the model instance. All the internal state is shared between the models.
        // The reason we can't use the exact same instance, and we have to clone it, is because of VisibilityManager & Selector.
        // When a model is added to a viewer, the specific viewer instance is being used inside the selector, and
        // every model can have a single Selector & VisibilityManager.
        const modelClone = model.clone();
    
        const loadCompleteCB = () => {
            modelClone.setInnerAttributes(model.getInnerAttributes()); // Need to update because of _consolidationIterator changes.
            viewer.impl.onLoadComplete(modelClone);
        };
    
        if (modelClone.isLoadDone()) {
            loadCompleteCB();
        } else {
    
            function onGeomLoaded(event) {
                if (model === event.model) {
                    hostViewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, onGeomLoaded);
                    hostViewer.impl.invalidate = hostViewer.originalInvalidate;
                    loadCompleteCB();
                }
            };
            
            hostViewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, onGeomLoaded);
    
            // Until all the geometries are loaded - proxy all the invalidate calls of the host viewer into the leech viewer too.
            // This way it will get an update when a new mesh is there for being rendered.
            // TODO: A better way of doing this is adding a MESH_RECEIVED event on all the loaders (Same usage as TEXTURES_LOADED_EVENT).
            hostViewer.impl.invalidate = (needsClear, needsRender, overlayDirty) => {
                if (needsClear || needsRender || overlayDirty) {
                    viewer.impl.invalidate(needsClear, needsRender, overlayDirty);
                }
    
                hostViewer.originalInvalidate(needsClear, needsRender, overlayDirty);
            }
        }
    
        if (!modelClone.getData().texLoadDone) {
            function onTextureLoadComplete(event) {
                if (model === event.model) {
                    viewer.impl.invalidate(false, true);
                    hostViewer.removeEventListener(Autodesk.Viewing.TEXTURES_LOADED_EVENT, onTextureLoadComplete);
                }
            };
        
            hostViewer.addEventListener(Autodesk.Viewing.TEXTURES_LOADED_EVENT, onTextureLoadComplete);
        }
    
        return modelClone;
    }    

    return new HostViewer(container, config, ViewerClass);
};

