import { createLeechViewer } from './LeechViewer';
import { createHostViewer } from './HostViewer';

// MultiViewerFactory - encapsulates the creation of host & leech viewers.
export class MultiViewerFactory {
    constructor() {
        this.hostViewer = null;
    }

    createViewer(container, config, ViewerClass = Autodesk.Viewing.Viewer3D) {
        if (!this.hostViewer) {
            this.hostViewer = createHostViewer(container, config, ViewerClass);
            return this.hostViewer;
        } else {
            return createLeechViewer(container, config, this.hostViewer, ViewerClass);
        }
    }
}
