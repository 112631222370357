import LeechViewerRenderContext from './LeechViewerRenderContext';

// LeechViewer is a regular viewer, that lets you share resources (Models, Geometries & Materials) with other viewers.
// The trick is using a single HostViewer to maintain all the resources, and use its renderer to render them.
// After rendering a frame, the result gets copied into the LeechViewer's canvas.

const protoCache = new Map();

// In order to support the ability to dynamically decide which ViewerClass to use, I had to wrap the initialization of the LeechViewer prototype in a function.
export function createLeechViewer(container, config, hostViewer, ViewerClass) {
    
    // In order to still share prototypes between different instances of leech viewers, we save the prototype in a cache.
    if (protoCache.has(ViewerClass)) {
        const proto = protoCache.get(ViewerClass);
        return new proto(container, config, hostViewer);
    }

    function LeechViewer(container, config = {}, hostViewer) {
        this.hostViewer = hostViewer;
    
        ViewerClass.call(this, container, config);
    
        this.renderContext = new LeechViewerRenderContext();

        // Needed in order to sync the ID targets usage of the renderer.
        this.renderContext.mrtFlags = this.hostViewer.impl.renderer().mrtFlags;

        // Always use same config as in the host's renderer, so it won't change anything in the configuration inside webglRenderer.
        this.renderContext.getConfig = this.hostViewer.impl.renderer().getConfig;

        // Canvas is being created only inside GuiViewer3D constructor.
        this.renderContext.setCanvas(this.canvas);
    }
    
    LeechViewer.prototype = Object.create(ViewerClass.prototype);
    LeechViewer.prototype.constructor = LeechViewer;
    
    LeechViewer.prototype.initialize = function() {
        // Use the host-viewer's webGLRenderer and material manager instances.
        const initOptions = {
            glrenderer: this.hostViewer.impl.glrenderer(),
            materialManager: this.hostViewer.impl.matman(),
            renderer: this.renderContext
        };
    
        const nopViewerBackup = this.getWindow().NOP_VIEWER;
    
        const viewerErrorCode = ViewerClass.prototype.initialize.call(this, initOptions);
    
        this.getWindow().NOP_VIEWER = nopViewerBackup;
    
        // Before each tick, make sure the viewport is set to the right size.
        const originalTick = this.impl.tick.bind(this.impl);
        this.impl.tick = (...args) => {
            this.impl.renderer().prepareViewport();
            // Since geometries get loaded through the host viewer, we need to copy lastTime2dGeometryCreated to the leech - otherwise it won't get updated.
            this.impl.lastTime2dGeometryCreated = this.hostViewer.impl.lastTime2dGeometryCreated;
            originalTick(...args);
            this.impl.renderer().restoreViewport();
        };

        // Only after renderPresented is done, copy the render target into the LeechViewer's canvas.
        // Priority is set to -1000, so in case of other event-listeners that should render to the target too, they'll do that prior to this.
        this.addEventListener(Autodesk.Viewing.RENDER_PRESENTED_EVENT, () => {
            this.renderContext.renderToCanvas();
        }, { priority: -1000 });

        const originalSetLightPreset = this.impl.setLightPreset.bind(this.impl);
        this.impl.setLightPreset = (index, force, callback) => {
            if (this.impl.is2d) {
                // In case of 2d, don't call `setLightPreset`, because it can unload 3D material resources that being used by other viewers.
                // Only set the background according to the light preset.
                const bgColor2d = Autodesk.Viewing.Private.LightPresets[index].bgColorGradient;
                this.setBackgroundColor(...bgColor2d);
            } else {
                originalSetLightPreset(index, force, callback);
            }
        };
    
        // Register viewer in order to get all the events, and be managed by the host viewer.
        this.hostViewer.registerViewer(this);
    
        return viewerErrorCode;
    };
    
    protoCache.set(ViewerClass, LeechViewer);

    return new LeechViewer(container, config, hostViewer, ViewerClass);
};
