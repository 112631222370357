import { RenderContext } from "../wgs/render/RenderContext";

// LeechViewerRenderContext uses the hostViewer's webglRenderer in order to render.
// Instead of rendering directly into the canvas, an offscreen target is being used,
// and then copied into a 2D canvas.

export default function LeechViewerRenderContext() {
    Autodesk.Viewing.Private.RenderContext.call(this);

    this.originalInit = this.init.bind(this);
    this.init = function(glrenderer, width, height) {
        // isOffscreen has to be set to true.
        this.originalInit(glrenderer, width, height, { offscreen: true});
    
        this.glrenderer = glrenderer;
    
        const pixelRatio = this.glrenderer.getPixelRatio();
    
        this.setSize(width / pixelRatio, height / pixelRatio);
    };

    this.renderToCanvas = function() {
        // Copy pixels from offscreenTarget to the 2D canvas.
        this.glrenderer.readRenderTargetPixels(this.offscreenTarget, 0, 0, this.offscreenTarget.width, this.offscreenTarget.height, this.imageBuffer);
        this.ctx2D.putImageData(this.imageData, 0, 0);
        this.ctx2D.drawImage(this.canvas, 0, 0, this.offscreenTarget.width, this.offscreenTarget.height);
    }
    
    this.originalSetSize = this.setSize.bind(this);
    this.setSize = function(width, height, force, suppress) {
        if (!suppress) {
            const pixelRatio = this.glrenderer.getPixelRatio();
    
            this.canvas.width = width * pixelRatio;
            this.canvas.height = height * pixelRatio;
            this.canvas.style.width = `${width}px`;
            this.canvas.style.height = `${height}px`;
    
            this.ctx2D = this.canvas.getContext('2d');
            this.ctx2D.translate(0, height * pixelRatio);
            this.ctx2D.scale(1, -1);
            this.imageData = this.ctx2D.createImageData(width * pixelRatio, height * pixelRatio);
            this.imageBuffer = new Uint8Array(this.imageData.data.buffer);
    
            this.offscreenTarget = new THREE.WebGLRenderTarget(width * pixelRatio, height * pixelRatio,
                { minFilter: THREE.LinearFilter,
                    magFilter: THREE.LinearFilter,
                    format: THREE.RGBFormat,
                    type: THREE.UnsignedByteType
                });
    
            this.setOffscreenTarget(this.offscreenTarget);
        }
    
        this.prepareViewport(force, suppress);
        this.restoreViewport();
    };

    this.prepareViewport = function(force, suppress) {
        const pixelRatio = this.glrenderer.getPixelRatio();
        this.glrenderer.pushViewport();
        this.originalSetSize(this.offscreenTarget.width / pixelRatio, this.offscreenTarget.height / pixelRatio, force, suppress);
    };

    this.restoreViewport = function() {
        this.glrenderer.popViewport();
    };

    this.setCanvas = function(canvas) {
        this.canvas = canvas;
    };
}

LeechViewerRenderContext.prototype = Object.create(RenderContext.prototype);
LeechViewerRenderContext.prototype.constructor = LeechViewerRenderContext;
