export default class CrossViewerInteractionManager {
    constructor(hostViewer) {
        this.hostViewer = hostViewer;
        this.locks = {};
        this.events = [];
    }

    // cb should be with a signature of: (sourceViewer, targetViewer, event) -> void
    addCrossViewerInteraction({ eventName, cb }) {
        this.events.push({
            eventName,
            cb,
            registeredViewers: []
        });

        this.registerViewers();
    }

    registerViewers() {
        for (let i = 0; i < this.events.length; i++) {
            const { eventName, cb, registeredViewers } = this.events[i];
            
            for (let j = 0; j < this.hostViewer.viewers.length; j++) {
                const viewer = this.hostViewer.viewers[j];

                // registeredViewers is an array of viewers that registered for this specific event.
                if (registeredViewers.indexOf(viewer) === -1) {
                    viewer.addEventListener(eventName, (e) => {
                        this.dispatchForAllViewers(viewer, cb, e);
                    });

                    registeredViewers.push(viewer);
                }
            }
        }
    }

    dispatchForAllViewers(sourceViewer, cb, event) {
        // In order that a viewer won't trigger he same event again, and then we'll end up in an infinite loop,
        // there is a lock per event type here. An example of such scenario is for a selection event.
        if (this.locks[event.type])
            return;

        this.locks[event.type] = true;

        for (let i = 0; i < this.hostViewer.viewers.length; i++) {
            const targetViewer = this.hostViewer.viewers[i];

            if (targetViewer === sourceViewer) {
                continue;
            }

            // Currently a single cb is available for every eventName.
            // If it will be needed in the future to expand it, a loop can be added here instead.
            cb(sourceViewer, targetViewer, event);
        }

        this.locks[event.type] = false;
    }
}
